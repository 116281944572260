import {ISiteMapPageLink, IWixColor} from '@wix/native-components-infra/dist/src/types/wix-sdk'
import {MEMBERS_APP_DEF_ID, getInstance as getWixInstance, WixSDK as Wix} from '@wix/wix-events-commons-statics'
import {ColorPickerResponse, EditorFontPickerConfig, EditorFontPickerResponse} from '../types/sdk-types'

export const WixSDK = Wix

export const setHeight = (height: number) => Wix.setHeight(height)

export const resizeComponent = (options: {width?: number; height?: number}): Promise<void> =>
  new Promise((resolve, reject) => {
    Wix.resizeComponent(options, resolve, reject)
  })

export const setSdkData = (
  key: string,
  value: any,
  options: ScopeOptions,
  onSuccess?: Function,
  onFailure?: Function,
) => Wix.Data.Public.set(key, value, options, onSuccess, onFailure)

export const getSdkData = (key: string, options: ScopeOptions, onSuccess: Function, onFailure?: Function) =>
  Wix.Data.Public.get(key, options, onSuccess, onFailure)

export const removeSdkData = (key: string, options: ScopeOptions, onSuccess?: Function, onFailure?: Function) =>
  Wix.Data.Public.remove(key, options, onSuccess, onFailure)

export const getPublicApi = (onSuccess: Function) => Wix.getPublicAPI({appDefinitionId: MEMBERS_APP_DEF_ID}, onSuccess)

export const getInstanceValue = (param: string) => Wix.Utils.getInstanceValue(param)

export const getMetaSiteId = () => getInstanceValue('metaSiteId')

export const getLocale = () => Wix.Utils.getLocale()

export const closeWindow = (options?: any) => Wix.closeWindow(options)

export const addEventListener = (event: string, callback: Function) => Wix.addEventListener(event, callback)

export const getStyleParams = (callback?: Function) => Wix.Styles.getStyleParams(callback)

export const getSiteColors = () => Wix.Styles.getSiteColors()

export const setBooleanParam = (key: string, value: boolean) => {
  Wix.Styles.setBooleanParam(key, {value})
  setUIParam('booleans', key, value)
}

export const setNumberParam = (key: string, value: number) => {
  Wix.Styles.setNumberParam(key, {value})
  setUIParam('numbers', key, value)
}

export const setUIParam = (domain: string, key: string, value: any) => Wix.Styles.setUILIBParamValue(domain, key, value)

export const getSiteTextPresets = () => Wix.Styles.getSiteTextPresets()

export const openFontPicker = (options: EditorFontPickerConfig, onChange: (res: EditorFontPickerResponse) => void) =>
  Wix.Styles.openFontPicker(options, onChange)

export const openColorPicker = (options, onChange: (res: ColorPickerResponse) => void) =>
  Wix.Styles.openColorPicker(options, onChange)

export const getColorByReference = (reference: string): string =>
  (Wix.Styles.getColorByreference(reference) as IWixColor).value || reference

export const getInstance = () => getWixInstance()

export const getViewMode = () => Wix.Utils.getViewMode()

export const isMobile = () => Wix.Utils.getDeviceType() === 'mobile'

export const getCompId = () => Wix.Utils.getCompId()

export const getOrigCompId = () => Wix.Utils.getOrigCompId()

export const subscribe = (eventName: string, callback: Function, receivePastEvents: boolean) =>
  Wix.PubSub.subscribe(eventName, callback, receivePastEvents)

export const unsubscribe = (eventName: string, id: number) => Wix.PubSub.unsubscribe(eventName, id)

export const triggerSettingsUpdated = (type: string, compId: string, payload?: object) => {
  Wix.Settings.triggerSettingsUpdatedEvent({type, ...payload}, compId)
}

export const getSiteMap = (callback: (siteMap: ISiteMapPageLink[]) => void) => {
  Wix.getSiteMap(callback)
}

export const navigateToSection = (slug: string, sectionId = 'events') => {
  Wix.Utils.navigateToSection({sectionId}, slug)
}

export const openManageEvents = () => {
  Wix.Settings.closeWindow()
  Wix.PubSub.publish('OB_ON_MANAGE', {state: ''})
}

export const openDashboard = (path: string, onCloseCb?: Function) => {
  const onClose = () => {
    Wix.Settings.refreshApp()
    onCloseCb && onCloseCb()
  }
  Wix.Settings.openDashboard({onClose, state: path})
}

export const openModal = (url: string, width: number, height: number, title?: string, callback?: Function) => {
  Wix.Settings.openModal(url, width, height, title, callback)
}

export const openReviewInfo = () => Wix.Settings.openReviewInfo()

export const closeSettings = () => Wix.Settings.closeWindow({target: 'SETTINGS'})

export const isAppSectionInstalled = (
  appSectionId: string,
  appDefinitionId: string,
  callback: (isInstalled: boolean) => void,
) => Wix.isAppSectionInstalled(appSectionId, {appDefinitionId}, callback)

export const getAppApi = (): Promise<EventsApi> => {
  return new Promise((resolve, reject) =>
    Wix.Settings.getAppAPI(
      api => resolve(api),
      error => reject(error),
    ),
  )
}

export const getResizedImageUrl = (imageId: string, width: number, height: number) =>
  Wix.Utils.Media.getResizedImageUrl(imageId, width, height)

export const setHelpArticle = (articleId: string) => Wix.Settings.setHelpArticle(articleId)

export const openAdiHelp = (url: string) => Wix.PubSub.publish('OB_ON_HELP', {url})

export const openAdiBusinessManager = () => Wix.PubSub.publish('OB_ON_MANAGE', {state: ''})

export const Events = Wix.Events

interface ScopeOptions {
  scope: 'APP' | 'COMPONENT'
}
