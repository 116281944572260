import {withNamespaces} from 'react-i18next'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {MobileDownloadTicketsButton as Presentation} from './mobile-download-tickets-button'
import {MobileDownloadTicketsButtonRuntimeProps, MobileDownloadTicketsButtonOwnProps} from './interfaces'

const mapRuntime = ({
  actions: {downloadTicketsAction},
  state,
}: MemberPageContext): MobileDownloadTicketsButtonRuntimeProps => ({
  tickets: state.tickets,
  downloadTicketsAction,
})

export const MobileDownloadTicketsButton = connect<
  MobileDownloadTicketsButtonOwnProps,
  MobileDownloadTicketsButtonRuntimeProps
>(mapRuntime)(withNamespaces()(Presentation))
export * from './interfaces'
