import * as React from 'react'
import * as s from './error-page.scss'

export const ErrorPage = () => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.location.reload()
  }

  return (
    <div dir="ltr">
      <div className={s.container}>
        <div className={s.box}>
          <h2 className={s.title}>Something went wrong</h2>

          <p className={s.subtitle}>Refresh your page to try again</p>

          <button className={s.button} onClick={handleClick}>
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  )
}
